import { ChainId } from 'ramenswap-sdk';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Text } from 'rebass';

import styled from 'styled-components';

// import Logo from '../../assets/images/mainlogo.png'
import { useActiveWeb3React } from '../../hooks';
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks';

import { NavbarCard } from '../Card';
import Settings from '../Settings';
import Menu from '../Menu';

import { RowBetween } from '../Row';
import Web3Status from '../Web3Status';
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 50;
  background-color: ${({ theme }) => theme.heroBG};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;
  gap: 10.79px;

  :hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`;

const TitleName = styled.h1`
  font-size: 20px;
  font-weight: 580;
  text-align: left;
  line-height: 28.28px;
  color: #ffffff;
  margin: 0;
`;

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg6)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid #0a98ff;
  }
`;

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`;

const NetworkCard = styled(NavbarCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: fit-content;
  margin-right: 8px;
  border-radius: 10px 14px 10px 14px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 510;
  color: #ffffff;

  :hover {
    cursor: default;
  }
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`;

const BalanceText = styled(Text)`
  font-size: 14px;
  font-weight: 510;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'Artela',
  [ChainId.ARTELATESTNET]: 'Artela Testnet',
  [ChainId.ARTELALOCAL]: 'Artela Local',
  [ChainId.ARTELADEVNET]: 'Artela Devnet',
};

export default function Header() {
  const { account, chainId } = useActiveWeb3React();

  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ];
  //const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        padding="1rem 4rem 1rem 4rem"
      >
        <HeaderElement>
          <Title href=".">
            <img
              style={{ height: 23.55, width: 26.86 }}
              src="/logos/artex-blue.svg"
              alt="logo"
            />
            <TitleName>ArtexSwap</TitleName>
          </Title>
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                <NetworkCard>
                  <img src="/images/tokens/ETH.svg" alt="icon" />
                  {NETWORK_LABELS[chainId]}
                </NetworkCard>
              )}
            </TestnetWrapper>
            <AccountElement
              active={!!account}
              style={{ pointerEvents: 'auto' }}
            >
              {account && userEthBalance ? (
                <BalanceText
                  style={{ flexShrink: 0 }}
                  pl="0.75rem"
                  pr="0.5rem"
                  fontWeight={500}
                >
                  {userEthBalance?.toSignificant(4)} ART
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <VersionSwitch /> */}
            <Settings />
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  );
}
