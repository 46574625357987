import { Currency, DEV, Token } from 'ramenswap-sdk';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ArtelaLogo from '../../assets/tokens/art.png';
import useHttpLocations from '../../hooks/useHttpLocations';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import Logo from '../Logo';

const localLogos: { [address: string]: string } = {
  '0xD40e7387D4f16c885C14F0D54F58055737d0035F': require('../../assets/tokens/tok.webp'),
  '0x4997b8907b6fD3C30de941cDB79d273428586e78': require('../../assets/tokens/dai.svg'),
  '0x6cDdd437CeD8a9Af4D53Ee51B1645Bbd08F9B702': require('../../assets/tokens/usdt.svg'),
  '0x557CEB8739640B02A547a04643089acCB3b88E03': require('../../assets/tokens/eth.svg'),
};

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;

const StyledArtelaLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );

  const srcs: string[] = useMemo(() => {
    if (currency === DEV) return [];

    if (currency instanceof Token) {
      const localLogo = localLogos[currency.address];

      if (localLogo) {
        return [localLogo];
      }

      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)];
      }

      return [getTokenLogoURL(currency.address)];
    }
    return [];
  }, [currency, uriLocations]);

  if (currency === DEV) {
    return <StyledArtelaLogo src={ArtelaLogo} size={size} style={style} />;
  }  

  return (
    <StyledLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
    />
  );
}
