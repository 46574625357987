import { AbstractConnector } from '@web3-react/abstract-connector';
import React from 'react';
import styled from 'styled-components';
import Option from './Option';
import { SUPPORTED_WALLETS } from '../../constants';
// import { injected } from '../../connectors';
import { darken } from 'polished';
import Loader from '../Loader';

const PendingSection = styled.div`
    ${({ theme }) => theme.flexColumnNoWrap};
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    & > * {
        width: 100%;
    }
`;

const StyledLoader = styled(Loader)`
    margin-right: 1rem;
`;

const LoadingMessage = styled.div<{ error?: boolean }>`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    margin-bottom: 20px;
    color: ${({ theme, error }) => (error ? theme.red1 : 'inherit')};
    border: 1px solid ${({ theme, error }) => (error ? theme.red1 : theme.text4)};
    & > * {
        padding: 1rem;
    }
`;

const ErrorGroup = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: flex-start;
`;

const ErrorButton = styled.div`
    border-radius: 8px;
    font-size: 12px;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg4};
    margin-left: 1rem;
    padding: 0.5rem;
    font-weight: 600;
    user-select: none;
    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => darken(0.1, theme.text4)};
    }
`;

const LoadingWrapper = styled.div`
    ${({ theme }) => theme.flexRowNoWrap};
    align-items: center;
    justify-content: center;
`;

export default function PendingView({
                                      connector,
                                      error = false,
                                      setPendingError,
                                      tryActivation,
                                    }: {
  connector?: AbstractConnector;
  error?: boolean;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: AbstractConnector) => void;
}) {
  // const isMetamask = window?.ethereum?.isMetaMask;

  // 调整顺序：将 OKX 放在 Metamask 前面
  const walletOrder = ['OKX', ...Object.keys(SUPPORTED_WALLETS).filter(key => key !== 'OKX')];

  return (
    <PendingSection>
      <LoadingMessage error={error}>
        <LoadingWrapper>
          {error ? (
            <ErrorGroup>
              <div>Error connecting.</div>
              <ErrorButton
                onClick={() => {
                  setPendingError(false);
                  connector && tryActivation(connector);
                }}
              >
                Try Again
              </ErrorButton>
            </ErrorGroup>
          ) : (
            <>
              <StyledLoader />
              Initializing...
            </>
          )}
        </LoadingWrapper>
      </LoadingMessage>
      {walletOrder.map((key) => {
        const option = SUPPORTED_WALLETS[key];
        if (option.connector === connector) {
          // if (option.connector === injected) {
          //   if (isMetamask && option.name !== 'MetaMask') {
          //     return null;
          //   }
          //   if (!isMetamask && option.name === 'MetaMask') {
          //     return null;
          //   }
          // }
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              subheader={option.description}
              icon={require('../../assets/images/' + option.iconName)}
            />
          );
        }
        return null;
      })}
    </PendingSection>
  );
}
