import React, { useRef } from 'react';
import { ExternalLink } from '../../theme';

import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as WebsiteIcon } from '../../assets/images/website.svg';
import { ReactComponent as DocumentsIcon } from '../../assets/images/documents.svg';
// import { ReactComponent as PlusIcon } from "../../assets/images/plus-blue.svg";

import useToggle from '../../hooks/useToggle';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const StyledMenuIcon = styled(MenuIcon)`
  height: 20px;
  width: 20px;
  path {
    fill: ${({ theme }) => theme.secondary4};
  }
`;

const StyledMenuButton = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  height: 35px;
  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg6};
    svg > * {
      fill: ${({ theme }) => theme.text1};
    }
  }

  svg {
    margin-top: 2px;
  }
`;

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`;

const MenuFlyout = styled.span`
  width: 12.125rem;
  background-color: ${({ theme }) => theme.navbarBG};
  //box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04),
  //  0 16px 24px rgba(0, 0, 0, 0.04), 0 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 100;
`;

const MenuItem = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  //flex: 1;
  padding: 10px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.text1};
  :hover {
    background-color: ${({ theme }) => theme.bg6};
    cursor: pointer;
    text-decoration: none;
  }
`;

export default function Menu() {
  const node = useRef<HTMLDivElement>();
  const [open, toggle] = useToggle(false);
  const { t } = useTranslation();

  useOnClickOutside(node, open ? toggle : undefined);

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <MenuItem id="link" href="https://info.artexswap.com/">
            <WebsiteIcon style={{ width: '14px', height: '14px' }} />
            {t('Analytics')}
          </MenuItem>
          <MenuItem id="link" href="https://docs.artexswap.com/">
            <DocumentsIcon style={{ width: '14px', height: '14px' }} />
            {t('Documents')}
          </MenuItem>
          {/* <MenuItem id="link" href="https://info.artexswap.xyz/">
            <PlusIcon style={{ width: "14px", height: "14px" }} />
            {t("Analytics")}
          </MenuItem> */}
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}
