import React, { useContext, useRef, useState } from 'react';
import { X } from 'react-feather';
import { ReactComponent as Settings } from '../../assets/images/settings.svg';
import styled, { ThemeContext } from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from '../../state/user/hooks';
//useDarkModeManager
import TransactionSettings from '../TransactionSettings';
import { RowBetween, RowFixed } from '../Row';
import { TYPE } from '../../theme';
import QuestionHelper from '../QuestionHelper';
import Toggle from '../Toggle';
import { AutoColumn } from '../Column';
import { ButtonError } from '../Button';
import {
  useSettingsMenuOpen,
  useToggleSettingsMenu,
} from '../../state/application/hooks';
import { Text } from 'rebass';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';

const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    fill: ${({ theme }) => theme.secondary4};
  }
`;

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    fill: ${({ theme }) => theme.text1};
  }
`;

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  border: none;
  margin: 0;
  height: 35px;
  background-color: transparent;

  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg6};
    svg > * {
      fill: ${({ theme }) => theme.text1};
    }
  }

  svg {
    margin-top: 2px;
  }
`;
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0;
  font-size: 14px;
`;

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`;

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.navbarBG};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04),
    0 24px 32px rgba(0, 0, 0, 0.01);

  //border: 1px solid ${({ theme }) => theme.bg3};
  border: none;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 18.125rem;
    right: -46px;
  `};
`;

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 20px;
`;

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>();
  const open = useSettingsMenuOpen();
  const toggle = useToggleSettingsMenu();
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const [
    userSlippageTolerance,
    setUserslippageTolerance,
  ] = useUserSlippageTolerance();

  const [deadline, setDeadline] = useUserDeadline();

  const [expertMode, toggleExpertMode] = useExpertModeManager();

  //const [darkMode, toggleDarkMode] = useDarkModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false);

  useOnClickOutside(node, open ? toggle : undefined);

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal
        isOpen={showConfirmation}
        onDismiss={() => setShowConfirmation(false)}
        maxHeight={100}
      >
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={500} fontSize={20}>
                {t('areYouSure')}
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={500} fontSize={20}>
                {t('expertModeTips1')}
              </Text>
              <Text fontWeight={600} fontSize={20}>
                {t('expertModeTips2')}
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (
                    window.prompt(
                      `Please type the word "confirm" to enable expert mode.`
                    ) === 'confirm'
                  ) {
                    toggleExpertMode();
                    setShowConfirmation(false);
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  {t('turnOnExpertMode')}
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <StyledMenuIcon />
        {expertMode && (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        )}
      </StyledMenuButton>
      {open && (
        <MenuFlyout>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14}>
              {t('Swap')}
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={deadline}
              setDeadline={setDeadline}
            />
            <Text fontWeight={600} fontSize={14}>
              {t('Interface')}
            </Text>
            <RowBetween>
              <RowFixed>
                <TYPE.black
                  fontWeight={400}
                  fontSize={14}
                  color={theme.secondary4}
                >
                  {t('Professional')}
                </TYPE.black>
                <QuestionHelper text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                        toggleExpertMode();
                        setShowConfirmation(false);
                      }
                    : () => {
                        toggle();
                        setShowConfirmation(true);
                      }
                }
              />
            </RowBetween>
          </AutoColumn>
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}

/*
<RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  {t('toggleDarkMode')}
                </TYPE.black>
              </RowFixed>
              <Toggle isActive={darkMode} toggle={toggleDarkMode} />
            </RowBetween>
  */
